$font-family-sans-serif: 'Roboto', Helvetica, Arial, sans-serif;
$brand-primary: $color-primary;
$text-color: $color-black;
$link-color: $color-black;
$link-hover-color: $color-primary;
$link-hover-decoration: none;

$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

$icon-font-path: "../../fonts/bootstrap/";

.form-control {
    margin-bottom: $margin-vertical-space;
}

.btn {
    text-transform: uppercase;
}
