.full-width {
    width: 100%;
}

.notification {
    padding: $margin-vertical-space;
}

.screen {
    display: none;
    font-weight: 700;
    font-size: 200%;
    text-align: center;
    color: #aaa;
}

.block {
    display: block;
}

.link {
    color: $color-primary;
}
