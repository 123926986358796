table.dataTable thead .sorting {
  background-image: none;
}

table.dataTable thead .sorting_asc {
    background-image: none;
}

table.dataTable thead .sorting_desc {
    background-image: none;
}

table.dataTable thead .sorting_asc_disabled {
    background-image: none;
}

table.dataTable thead .sorting_desc_disabled {
    background-image: none;
}

/* these overrides border colors to match the bootstrap coloring */
table.dataTable thead th, table.dataTable thead td {
    border-bottom: 1px solid #ddd;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #ddd;
}

table.dataTable tbody tr {
    cursor: pointer;
}

table.dataTable tbody tr:hover {
    background-color: #eee;
    color: #FEC057;
}
