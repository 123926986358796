#measurement-map {
    width: 100%;
    height: 300px;
}

#measurement-time {
    font-weight: 700;
    font-size: 18px;
}

#measurement-map {
    margin-bottom: $margin-vertical-space;
}

#measurement-report img {
    width: 100%;
    height: auto;
}

#snowtam-operationally-significant-information {
    text-transform: uppercase;
}

#deposit-data {
    table-layout: fixed;
    margin: 40px 0 0 0;
}

#deposit-data thead td {
    font-size: 11px;
    border: 0;
    transform: rotate(-90deg);
    text-align: left;
    vertical-align: middle;
    line-height: 40px;
    white-space: nowrap;
}

#deposit-data td {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    text-align: center;
}

#deposit-data .num-col td{
    height: 40px;
    border: 0;
    vertical-align: bottom;
}

#deposit-data tr td:nth-of-type(1) {
    border: 0;
    width: 60px;
}

#deposit-data .deposit-label {
    vertical-align: middle;
    line-height: 40px;
    font-size: 11px;
}

#deposit-data .deposit-active {
    font-size: 10px;
    vertical-align: center;
    line-height: 20px;
    background-color: $color-primary;
}

.measurement-header {
    font-weight: bold;
    padding: 10px 0;
}

#runway-visualization {
    width: 100%;

    overflow-x: scroll;
}



.metaReportTable {
    border-collapse:collapse;
    margin-top: 20px; 
    margin-bottom: 15px;
    max-width:1100px; 
    width: 100%;
    table-layout: fixed;
}

.metaReportHeader  {
    border:1px solid black; 
    background-color: #fabf8f; 
    padding: 5px 10px 10px;
    text-align: center;  

    
}

.colorTable {text-align:center;}
.greenText {color:#74b62e;}
.yellowText {color: #fcc415}
.orangeText {color:#f4920b;}
.redText {color:#be1623;}
.blackText {color:black;}

.borderedTable {
    border-collapse:collapse;
    border:1px solid black; 
}


.borderedTable td {    border-left: 1px solid black; border-right: 1px solid black;}

#general-meta-info {margin-bottom: 10px;}

#alert-notices-list li {color: red;}

.borderedTd {
    border:1px solid black;
    text-align: center;  
} 
.borderedTh {
    border: 1px solid black;
    text-align: center;  
    background: #fabf8f;
}

.striped-table > tbody > tr:nth-child(2n) { 
    background:#fcdbc0; /*#fbcda7;*/
}

.marginHeader {
    margin-top: 30px;
}

.halfMargin {
    margin-top: 16px;
}

.fullMargin {
    margin-top: 32px;
}

.resultsDiv {
    margin-left: 0px;
}

.chartjsChart {
  background-color: #ffffff
}

.marginLine {
    height: 4px;
    background-color: #fabf8f;
    margin: 30px 64px 30px 64px;
}

.inputNumberField {
    height: 28px;
    width:120px;
    margin-left: 4px;
}

.updateButton {
    margin-left: 4px;
    height: 28px;
    padding: 0px 10px;
}

.trendLabel {
    margin-left: 4px;
    height: 20px;
}