#site-header {
    background-color: $color-site-header-background;
    height: $height-site-header;
}

#site-header a {
    color: $color-white;
}

// exception to the rule above
#site-header .dropdown-menu a {
    color: $color-black;
}

#site-header a:hover {
    color: $color-primary;
}

#site-logo {
    height: $height-site-header;
    width: auto;
    margin-right: $height-site-header / 2;
    vertical-align: bottom;
}

#site-title {
    display: inline-block;
    @include font-site-header;
    color: $color-primary;
}

#site-user,
#site-domain {
    display: inline-block;
    @include font-site-header;
    cursor: pointer;
}

#site-notifications {
    margin-top: $margin-vertical-space;
    margin-bottom: $margin-vertical-space;
}

#site-notifications .item {
    padding: $margin-vertical-space;
}

#site-tabs {
    margin-top: $margin-vertical-space;
    margin-bottom: $margin-vertical-space;
}

#confirm-visualization-span {
	margin-top: 10px;
}
